import React, { useState } from 'react'
import styles from './Sidenav.module.scss'
import { Link } from 'react-scroll'

const Sidenav = () => {
	const [activeTooltip, setActiveTooltip] = useState(false)
	return (
		<div className={styles.sidenav}>
			<div className={styles.sidenav__content}>
				<Link
					className={styles.sidenav__linkDisabled}
					activeClass={styles.sidenav__linkActive}
					to="project"
					spy={true}
					smooth={true}
					offset={0}
					duration={500}
				>
					<div className={styles.sidenav__tooltip}>Медитация в твоем кармане</div>
					<svg
						className={styles.sidenav__linkDefault}
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g opacity="0.2">
							<rect
								x="6.65674"
								y="12"
								width="8"
								height="8"
								rx="2"
								transform="rotate(-45 6.65674 12)"
								fill="white"
							/>
						</g>
					</svg>
					<svg
						className={styles.sidenav__link}
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clip-path="url(#clip0_272_8105)">
							<rect
								x="1.39339"
								y="12"
								width="15"
								height="15"
								rx="3.5"
								transform="rotate(-45 1.39339 12)"
								stroke="white"
							/>
							<rect
								x="6.34302"
								y="12"
								width="8"
								height="8"
								rx="2"
								transform="rotate(-45 6.34302 12)"
								fill="white"
							/>
						</g>
						<defs>
							<clipPath id="clip0_272_8105">
								<rect width="24" height="24" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</Link>
				<Link
					className={styles.sidenav__linkDisabled}
					activeClass={styles.sidenav__linkActive}
					to="aboutEYWA"
					spy={true}
					smooth={true}
					offset={0}
					duration={500}
				>
					<div className={styles.sidenav__tooltip}>Что такое EYWA</div>
					<svg
						className={styles.sidenav__linkDefault}
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g opacity="0.2">
							<rect
								x="6.65674"
								y="12"
								width="8"
								height="8"
								rx="2"
								transform="rotate(-45 6.65674 12)"
								fill="white"
							/>
						</g>
					</svg>
					<svg
						className={styles.sidenav__link}
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clip-path="url(#clip0_272_8105)">
							<rect
								x="1.39339"
								y="12"
								width="15"
								height="15"
								rx="3.5"
								transform="rotate(-45 1.39339 12)"
								stroke="white"
							/>
							<rect
								x="6.34302"
								y="12"
								width="8"
								height="8"
								rx="2"
								transform="rotate(-45 6.34302 12)"
								fill="white"
							/>
						</g>
						<defs>
							<clipPath id="clip0_272_8105">
								<rect width="24" height="24" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</Link>
				<Link
					className={styles.sidenav__linkDisabled}
					activeClass={styles.sidenav__linkActive}
					to="stepsToBalance"
					spy={true}
					smooth={true}
					offset={0}
					duration={500}
				>
					<div className={styles.sidenav__tooltip}>Простые шаги к балансу</div>
					<svg
						className={styles.sidenav__linkDefault}
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g opacity="0.2">
							<rect
								x="6.65674"
								y="12"
								width="8"
								height="8"
								rx="2"
								transform="rotate(-45 6.65674 12)"
								fill="white"
							/>
						</g>
					</svg>
					<svg
						className={styles.sidenav__link}
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clip-path="url(#clip0_272_8105)">
							<rect
								x="1.39339"
								y="12"
								width="15"
								height="15"
								rx="3.5"
								transform="rotate(-45 1.39339 12)"
								stroke="white"
							/>
							<rect
								x="6.34302"
								y="12"
								width="8"
								height="8"
								rx="2"
								transform="rotate(-45 6.34302 12)"
								fill="white"
							/>
						</g>
						<defs>
							<clipPath id="clip0_272_8105">
								<rect width="24" height="24" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</Link>
				<Link
					className={styles.sidenav__linkDisabled}
					activeClass={styles.sidenav__linkActive}
					to="focus"
					spy={true}
					smooth={true}
					offset={0}
					duration={500}
				>
					<div className={styles.sidenav__tooltip}>Сосредоточтесь на самом важном</div>
					<svg
						className={styles.sidenav__linkDefault}
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g opacity="0.2">
							<rect
								x="6.65674"
								y="12"
								width="8"
								height="8"
								rx="2"
								transform="rotate(-45 6.65674 12)"
								fill="white"
							/>
						</g>
					</svg>
					<svg
						className={styles.sidenav__link}
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clip-path="url(#clip0_272_8105)">
							<rect
								x="1.39339"
								y="12"
								width="15"
								height="15"
								rx="3.5"
								transform="rotate(-45 1.39339 12)"
								stroke="white"
							/>
							<rect
								x="6.34302"
								y="12"
								width="8"
								height="8"
								rx="2"
								transform="rotate(-45 6.34302 12)"
								fill="white"
							/>
						</g>
						<defs>
							<clipPath id="clip0_272_8105">
								<rect width="24" height="24" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</Link>
				<Link
					className={styles.sidenav__linkDisabled}
					activeClass={styles.sidenav__linkActive}
					to="roadmap"
					spy={true}
					smooth={true}
					offset={0}
					duration={500}
				>
					<div className={styles.sidenav__tooltip}>Дорожная карта</div>
					<svg
						className={styles.sidenav__linkDefault}
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g opacity="0.2">
							<rect
								x="6.65674"
								y="12"
								width="8"
								height="8"
								rx="2"
								transform="rotate(-45 6.65674 12)"
								fill="white"
							/>
						</g>
					</svg>
					<svg
						className={styles.sidenav__link}
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clip-path="url(#clip0_272_8105)">
							<rect
								x="1.39339"
								y="12"
								width="15"
								height="15"
								rx="3.5"
								transform="rotate(-45 1.39339 12)"
								stroke="white"
							/>
							<rect
								x="6.34302"
								y="12"
								width="8"
								height="8"
								rx="2"
								transform="rotate(-45 6.34302 12)"
								fill="white"
							/>
						</g>
						<defs>
							<clipPath id="clip0_272_8105">
								<rect width="24" height="24" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</Link>
				<Link
					className={styles.sidenav__linkDisabled}
					activeClass={styles.sidenav__linkActive}
					to="download"
					spy={true}
					smooth={true}
					offset={0}
					duration={500}
				>
					<div className={styles.sidenav__tooltip}>Загрузить приложение</div>
					<svg
						className={styles.sidenav__linkDefault}
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g opacity="0.2">
							<rect
								x="6.65674"
								y="12"
								width="8"
								height="8"
								rx="2"
								transform="rotate(-45 6.65674 12)"
								fill="white"
							/>
						</g>
					</svg>
					<svg
						className={styles.sidenav__link}
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clip-path="url(#clip0_272_8105)">
							<rect
								x="1.39339"
								y="12"
								width="15"
								height="15"
								rx="3.5"
								transform="rotate(-45 1.39339 12)"
								stroke="white"
							/>
							<rect
								x="6.34302"
								y="12"
								width="8"
								height="8"
								rx="2"
								transform="rotate(-45 6.34302 12)"
								fill="white"
							/>
						</g>
						<defs>
							<clipPath id="clip0_272_8105">
								<rect width="24" height="24" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</Link>
			</div>
		</div>
	)
}

export default Sidenav
