import React from 'react'
import styles from './AboutEywaCard.module.scss'

const AboutEywaCard = (props) => {
	return (
		<div className={props.cardClassName}>
			<div className={styles.card__headerContent}>
				<img src={props.src} alt={props.alt} className={styles.card__img} />
				<h3 className={styles.card__header}>{props.header}</h3>
			</div>
			<p className={styles.card__content}>{props.content}</p>
		</div>
	)
}

export default AboutEywaCard
