import React from 'react'
import styles from './StepsToBalance.module.scss'

const StepsToBalance = (props) => {
	return (
		<div className={styles.card}>
			<div className={styles.card__headerContent}>
				<img src={props.src} alt={props.alt} className={styles.card__img} />
				<h4 className={styles.card__header}>{props.header}</h4>
			</div>
			<p className={styles.card__content}>{props.content}</p>
		</div>
	)
}

export default StepsToBalance
