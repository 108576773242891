import Layout from './components/layout/Layout'
import styles from './App.module.scss'
import './assets/fonts/fonts.css'

function App() {
	return (
		<div className={styles.main}>
			<Layout />
		</div>
	)
}

export default App
